import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.png"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.png"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.png"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageFourMobile from "./images/imagesMobile/imageThree.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Atiende a tus pacientes 24/7 con agentes inteligentes",
      description: "Las clínicas y laboratorios operan con horarios limitados, lo que restringe la atención a los pacientes que buscan agendar o consultar fuera del horario laboral. Esto puede generar pérdida de oportunidades y una percepción negativa del servicio. <br /><br /> Con Beex, puedes implementar agentes con IA que brinden soporte y permitan gestionar citas en cualquier momento del día. Al automatizar respuestas y consultas frecuentes, aseguras una experiencia de atención continua sin necesidad de personal adicional.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Integra los sistemas de historia clínica",
      description: "Los pacientes que han sido atendidos en distintas especialidades a menudo deben repetir información debido a la falta de integración entre los sistemas internos y los canales de atención. Esto ralentiza la gestión y afecta la eficiencia operativa. <br /><br /> Con Beex, puedes conectar tu CRM y sistemas de historia clínica con WhatsApp y llamadas automatizadas, permitiendo a los agentes acceder al historial de cada paciente en tiempo real. Esto mejora la personalización del servicio y optimiza el flujo de información en cada interacción.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Envía notificaciones personalizadas",
      description: "Las comunicaciones generales pueden no ser efectivas si no están personalizadas según las necesidades del paciente. Sin una estrategia de segmentación, las notificaciones pueden pasar desapercibidas o generar baja interacción. <br /><br /> Con Beex, puedes categorizar a los pacientes según historial, tipo de consulta o frecuencia de atención, enviando recordatorios, promociones o información relevante a través de WhatsApp y correo electrónico. Esto fortalece la relación con el paciente y mejora la tasa de respuesta a las campañas de salud.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-pandero">
      <p className="container-experience-pandero-title">
        <TitleUnderlineOne underline="Optimiza la atención" /> en salud para una experiencia más ágil
      </p>


      <div className="container-experience-pandero-cardsOne">
        <div>
          <section className="container-experience-pandero-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 6px 32px" }}>
            <section style={{ padding: "0px 26px" }}>
              <p className="container-experience-pandero-cardsOne-card-title">Atiende a tus pacientes 24/7 con agentes inteligentes </p>
              <p className="container-experience-pandero-cardsOne-card-description">
                Las clínicas y laboratorios operan con horarios limitados, lo que restringe la atención a los pacientes que buscan agendar o consultar fuera del horario laboral. Esto puede generar pérdida de oportunidades y una percepción negativa del servicio.
                <br /><br />
                Con Beex, puedes implementar agentes con IA que brinden soporte y permitan gestionar citas en cualquier momento del día. Al automatizar respuestas y consultas frecuentes, aseguras una experiencia de atención continua sin necesidad de personal adicional.
              </p>
            </section>
            <section>
              <img
                className="container-experience-pandero-cardsOne-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        <div className="container-experience-pandero-cardsTwo">
          <div className="container-experience-pandero-cardsOne-column">
            <section className="container-experience-pandero-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
              <section>
                <p className="container-experience-pandero-cardsOne-card-title">Integra los sistemas de historia clínica</p>
                <p className="container-experience-pandero-cardsOne-card-description">
                  Los pacientes que han sido atendidos en distintas especialidades a menudo deben repetir información debido a la falta de integración entre los sistemas internos y los canales de atención. Esto ralentiza la gestión y afecta la eficiencia operativa.
                  <br /><br />
                  Con Beex, puedes conectar tu CRM y sistemas de historia clínica con WhatsApp y llamadas automatizadas, permitiendo a los agentes acceder al historial de cada paciente en tiempo real. Esto mejora la personalización del servicio y optimiza el flujo de información en cada interacción.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsOne-card-image"
                  src={imageTwoDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
          <div className="container-experience-pandero-cardsTwo-cards-column">
            <section className="container-experience-pandero-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 6px" }}>
              <section>
                <p className="container-experience-pandero-cardsTwo-card-title">Integración con sistemas de registros de salud</p>
                <p className="container-experience-pandero-cardsTwo-card-description">
                  Beex permite conectar bases de datos como ERP o CRM para gestionar campañas más eficientes. Estas integraciones hacen posible el uso de marcadores predictivos para llamadas personalizadas o el envío masivo de mensajes por WhatsApp y correo electrónico.
                  <br /><br />
                  Con esta funcionalidad, puedes realizar campañas altamente segmentadas y efectivas, asegurando que los contactos sean relevantes y aumenten las probabilidades de éxito.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsTwo-card-image"
                  src={imageThreeDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
        </div>
      </div>


      <div className="container-experience-pandero-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-pandero-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-pandero-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-pandero-cards-mobile-card-description" dangerouslySetInnerHTML={{ __html: elemento.description }}>

                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-pandero-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
