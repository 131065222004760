/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Aviva from "@components/sharedComponents/customersLogos/logos/Aviva.svg"
import PulsoSalud from "@components/sharedComponents/customersLogos/logos/PulsoSalud.svg"
import Idisac from "@components/sharedComponents/customersLogos/logos/Idisac.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageAbm/pageSalud/image/imageOne.png"
import ImageAccordionLeftTwo from "@components/pageAbm/pageSalud/image/imageTwo.png"
import ImageAccordionLeftThree from "@components/pageAbm/pageSalud/image/imageThree.png"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageSalud.jpg"
import imgBanner from "@components/pageAbm/pageSalud/image/banner.png"

const titleStart = (<p className="guide__title">
    <span style={{ color: '#3878EB' }}>Cómo automatizar con IA</span>  la gestión de citas por WhatsApp y
    llamadas telefónicas en el   <span style={{ color: '#3878EB' }}> sector salud</span>
</p>
)

const descripcionStart = (<p>
    La gestión de citas médicas es un desafío clave en el sector salud. Actualmente, el 62% de los pacientes ha pospuesto tratamientos debido a problemas de accesibilidad y costos (McKinsey). Optimizar este proceso con IA no solo reduce tiempos, sino que mejora la experiencia del paciente.
    <br /><br />
    Descubre cómo instituciones como AUNA, SYNLAB y Clínica Aviva han transformado su atención al cliente, agilizando el agendamiento de citas hasta un 30% más rápido y mejorando los tiempos de respuesta en 95% con tecnología omnicanal.
</p>)

const data = {
    start: {
        title: titleStart,
        textbody: descripcionStart,
        img: "",
        banner: imgBanner,
        inputText: "Puesto de trabajo",
    },
    customersDesktop: [
        Rimac,
        Synlab,
        Auna,
        Aviva,
        PulsoSalud,
        Idisac
    ],
    customersTablet: {
        arrayOne: [Rimac,
            Synlab,
            Auna,
            Aviva,
            PulsoSalud,
            Idisac],
    },
    contacts: {
        title: "Optimiza la gestión de citas médicas con IA y brinda una atención más ágil",
        textbody: "Estás a un paso de transformar la experiencia de tus pacientes con automatización. Agenda una reunión para descubrir cómo reducir tiempos de espera, mejorar la atención y optimizar cada interacción.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    accordionRight: [
        {
            title: "Optimiza la asignación y confirmación de citas",
            description: "<p>Actualmente, la gestión de citas sigue procesos manuales que generan retrasos en la confirmación y saturan los canales de atención. Esto afecta la experiencia del paciente y reduce la eficiencia del equipo médico. <br /><br /> Con Beex, puedes automatizar la asignación y confirmación de citas en WhatsApp y llamadas, permitiendo que los pacientes gestionen sus consultas de forma rápida, sin intervención humana. Esto optimiza los tiempos de atención y reduce la carga operativa del personal. </p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Reduce ausencias con recordatorios automáticos",
            description: "<p>Las cancelaciones y ausencias por olvido afectan la planificación médica y generan pérdidas económicas. Sin recordatorios efectivos, las citas quedan vacantes sin posibilidad de reasignación inmediata. <br /> <br /> Con Beex, puedes programar recordatorios automáticos vía WhatsApp o llamadas, asegurando que los pacientes confirmen su asistencia o reagenden según disponibilidad. Esto permite reducir la tasa de no-shows y mejorar la eficiencia operativa en la atención médica.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Facilita el reagendamiento y cancelación de citas con bots",
            description: "<p>Reagendar o cancelar una cita a menudo requiere llamadas extensas o largas esperas, lo que puede generar frustración en los pacientes. Sin una opción digital, el proceso se vuelve poco práctico y afecta la experiencia del usuario.<br /><br /> Con Beex, los pacientes pueden modificar sus citas de manera autónoma a través de WhatsApp o llamadas automatizadas. Esto no solo agiliza el servicio, sino que también permite optimizar la asignación de horarios en tiempo real, asegurando una mejor gestión de disponibilidad médica.</p>",
            image: ImageAccordionLeftThree,
        },
    ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    bannerVideo: {
        title: "¿Cómo automatizar y brindar experiencias únicas en el sector salud?",
        description: "<p>Descubre cómo simplificar y mejorar la atención médica con automatización inteligente. Explora nuevas formas de proporcionar experiencias únicas en el sector salud. Optimiza procesos y maximiza la eficiencia para un cuidado más efectivo y personalizado. <br /><br /> Conoce cómo la tecnología transforma la manera en que interactuamos y cuidamos de la salud de todos.</p>",
        image: imageVideo
    },
}

export default data
